<!-- 文件描述: 采集看板--采集计数 -->
<template>
  <div class="acquisitionCount">
    <div class="active">
      <div class="active-info">
        <div class="item-info">
          <p class="lable">任务名称：</p>
          <el-input v-model="taskName" clearable placeholder="请输入任务名称" @change="searchData"><i slot="prefix"
              class="el-input__icon el-icon-search" @click="searchData"></i></el-input>
        </div>
        <div class="item-info">
          <p class="lable">任务时间：</p>
          <el-date-picker v-model="taskTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" @change="disposeTime">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="content">
      <el-table :data="tableData" stripe>
        <el-table-column prop="taskName" label="任务名称" min-width="200" :show-overflow-tooltip="true" align="center">
        </el-table-column>
        <el-table-column prop="sortId" label="sortID" width="80" :show-overflow-tooltip="true" align="center">
        </el-table-column>
        <el-table-column prop="type" label="类型" width="120" :show-overflow-tooltip="true" align="center">
        </el-table-column>
        <el-table-column prop="getDate" label="开始日期" width="120" :show-overflow-tooltip="true" align="center">
        </el-table-column>
        <el-table-column prop="hour" label="小时" width="80" :show-overflow-tooltip="true" align="center">
        </el-table-column>
        <el-table-column prop="count" label="计数" width="120" :show-overflow-tooltip="true" align="center">
        </el-table-column>
        <el-table-column prop="tableName" label="表名称" min-width="120" :show-overflow-tooltip="true" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="changeSize" @current-change="changeCurrent" :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getAcquisitionCount } from "@/http/api/acquisitionBoard.js"
export default {
  name: "acquisitionCount",
  data () {
    return {
      taskName: "",//任务名称
      taskTime: [],//任务时间
      tableData: [],//列表数据
      currentPage: 1,//当前页
      pageSize: 10,//每页显示条数
      total: 0,//总条数
      isHour: false
    };
  },
  mounted () {
    //获取列表数据
    this.getData();
  },
  methods: {
    //获取列表数据
    async getData () {
      let { body } = await getAcquisitionCount({
        pages: this.currentPage,
        rows: this.pageSize,
        taskName: this.taskName,
        startTime: this.taskTime[0] ? this.taskTime[0] : "",
        endTime: this.taskTime[1] ? this.taskTime[1] : "",
        isHour: this.isHour
      });
      if (body) {
        this.tableData = body.content;
        this.total = body.total;
      } else {
        this.tableData = [];
        this.total = 0;
      }
    },
    /**
     * @description 处理时间
     * @param 
     */
    disposeTime () {
      let num = 0
      let time = []
      this.taskTime.forEach(res => {
        console.log(res.split(' '));
        let arr = res.split(" ")
        if (arr[1] == '00:00:00') {
          time.push(arr[0])
          num++
        }
      })
      if (num >= 1) {
        this.isHour = false
        this.taskTime = time
      } else {
        this.isHour = true
      }
      this.searchData()
    },
    //根据任务名称搜索数据
    searchData () {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    //当前页
    changeCurrent (val) {
      this.currentPage = val;
      this.getData();
    },
    //每页显示条数
    changeSize (val) {
      this.pageSize = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.acquisitionCount {
  padding: 30px 24px;
}

.active {
  display: flex;
  margin-bottom: 30px;
}

.active-info {
  display: flex;
  align-items: center;

  .item-info {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .lable {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      white-space: nowrap;
    }
  }
}

//分页样式
.pagination {
  margin-top: 20px;
  text-align: right;
}

//表格样式
::v-deep .el-table th.el-table__cell {
  background: #EBF0FF;
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #F7FAFF;
}

::v-deep .el-table td.el-table__cell {
  border: none;
}
</style>